"use strict";

angular.module('informaApp')
    .service('DateExtensions', function () {
        return {
            setup: function () {
                Date.createDateWithoutTimezone = function (date) {
                    return new Date(moment(date).format('YYYY/MM/DD'));
                };

                Date.prototype.addHours = function(hours) {
                    this.setTime(this.getTime() + (hours * 60 * 60 * 1000));
                    return this;
                };
            }
        }
    });